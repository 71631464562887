<template>
    <BaseSheet
        v-if="userStore.loggedIn"
        :is-visible="isUserCardSheetOpen"
        class="user-card-sheet"
        @close="modalStore.close({ name: 'userCardSheet' })"
    >
        <template #title>
            Mijn We Are Public-pas
        </template>

        <template #subTitle>
            <p>Met deze pas heb je toegang tot alle programma’s op ons platform. Neem wel je ID mee.</p>

            <h4>{{ userStore.user.first_name }} {{ userStore.user.last_name }}</h4>
        </template>

        <template #default>
            <UserBarcode />
        </template>

        <template #footer>
            <img src="~/assets/img/logos/logo-black.svg">
        </template>
    </BaseSheet>
</template>

<script setup>
import { useModalStore } from '~/store/modal';
import { useUserStore } from '~/store/user';

const modalStore = useModalStore();
const userStore = useUserStore();

const isUserCardSheetOpen = computed(() => {
    return modalStore.activeModals.userCardSheet === true;
});
</script>

<style lang="less" src="./UserCardSheet.less" />
