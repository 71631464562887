<template>
    <div
        v-if="!isLoading && img"
        class="user-barcode"
    >
        <!-- eslint-disable -->
        <div
            v-html="img"
            class="user-barcode__image"
        />

        <span
            v-text="barcode"
            class="user-barcode__text"
        />
        <!-- eslint-enable -->
    </div>
</template>

<script setup lang="ts">
import { useUserStore } from '~/store/user';

const img = ref(null),
    isLoading = ref(true);

const userStore = useUserStore();

const barcode = computed(() => {
    return userStore.user?.barcode;
});

onMounted(async() => {
    const response = await useLaravelApi('/v1.0/users/me/barcode');

    if (response) {
        img.value = await response.text();
        isLoading.value = false;
    }
});
</script>
